import { EventContract, TaskContract } from '@moonpanda/moonpanda.contracts';
import { apiGetTasks } from 'src/api/tasks';
import { getUniqueId } from 'src/utils/getUniqueId';
import { create } from 'zustand';

import { BaseStoreEntityType, baseStoreEntityValue } from 'src/store/types';
import { apiGetEvents } from 'src/api/events';
import { ApiPaginateRequestType } from 'src/models/API';
import { showErrorToast } from 'src/utils/errors';
import { FNPropsType, useRequest } from 'src/hooks/useRequest';

type Actions = {
  start: () => void;
  stop: () => void;

  getEvents: (data: ApiPaginateRequestType) => void;
  addEvent: (event: EventContract) => void;
  updateEvent: (event: EventContract) => void;
  deleteEvent: (eventId: EventContract['id']) => void;

  getTasks: (data: ApiPaginateRequestType) => void;
};

type State = {
  request: {
    apiCaller: FNPropsType;
    abort: () => void;
  } | null;

  events: BaseStoreEntityType<(Omit<EventContract, 'id'> & { id: number | string })[]>;
  tasks: BaseStoreEntityType<TaskContract[]>;
};

const initialState: State = {
  request: null,

  events: baseStoreEntityValue([], true),
  tasks: baseStoreEntityValue([], true),
};

const useCalendarStore = create<State & Actions>()((set, get) => ({
  ...initialState,

  start: () => {
    const { apiCaller, abort } = useRequest.getStatic();

    set({
      request: {
        apiCaller,
        abort,
      },
    });
  },
  stop: () => {
    get().request?.abort();

    set({
      ...initialState,
    });
  },

  getEvents: (data) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      apiCaller(apiGetEvents, data)
        .then(({ data }) => {
          set({
            events: {
              response: data.map((event) => {
                if (event.id === 0) {
                  return {
                    ...event,
                    id: getUniqueId(),
                  };
                }

                return event;
              }),
              loading: false,
              error: null,
            },
          });
        })
        .catch((e) => {
          showErrorToast(e);

          set({
            events: baseStoreEntityValue([], false, e),
          });
        });
    }
  },
  addEvent: (event) => {
    set((state) => {
      if (state.events.response) {
        const events = state.events.response;

        events.push(event);

        return {
          ...state,
          events: {
            ...state.events,
            response: events,
          },
        };
      }

      return state;
    });
  },
  updateEvent: (event) => {
    set((state) => {
      const events = state.events.response;

      const index = events?.findIndex(({ id }) => id === event.id);

      if (events && index !== undefined) {
        events[index] = event;

        return {
          ...state,
          events: {
            ...state.events,
            response: events,
          },
        };
      }

      return state;
    });
  },
  deleteEvent: (eventId) => {
    set((state) => {
      const events = state.events.response;

      if (events) {
        return {
          ...state,
          events: {
            ...state.events,
            response: events.filter(({ id }) => id !== eventId),
          },
        };
      }

      return state;
    });
  },

  getTasks: (data) => {
    const apiCaller = get().request?.apiCaller;

    if (apiCaller) {
      apiCaller(apiGetTasks, data)
        .then(({ data }) => {
          set({
            tasks: baseStoreEntityValue(data, false),
          });
        })
        .catch((e) => {
          showErrorToast(e);

          set({
            tasks: baseStoreEntityValue([], false, e),
          });
        });
    }
  },
}));

export default useCalendarStore;
