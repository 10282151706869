import { EventContract } from '@moonpanda/moonpanda.contracts';
import { differenceInMinutes } from 'date-fns';
import isFunction from 'lodash/isFunction';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import useCalendarStore from 'src/pages/Calendar/store';
import { UiDeleteConfirmPopover } from 'src/components/UI/DeleteConfirmPopover';
import { IconDelete } from 'src/pages/Projects/icons/Delete';
import { UiProjectsDropdown } from 'src/components/UI/ProjectsDropdown';
import { UiButton } from 'src/components/UI/UiButton';
import { UiInput } from 'src/components/UI/inputs/Text';
import { useTHelper } from 'src/utils/i18n';
import { useStateForm } from 'src/utils/stateForm';
import { RecursiveNullable } from 'src/utils/types';
import { UiModal } from 'src/components/UI/Modals';
import useModalsStore from 'src/store/modals';
import { prepareSetDateToUiDatePicker, UiDatePicker } from 'src/components/UI/inputs/DatePicker';
import { UiTimePicker } from 'src/components/UI/inputs/TimePicker';
import { apiCreateEvent, apiDeleteEvent, apiUpdateEvent } from 'src/api/events';
import { UiTaginput } from 'src/components/UI/inputs/TagInput/TagInput';
import { UiToast } from 'src/components/UI/toasts';
import { useRequest } from 'src/hooks/useRequest';
import { CDate } from 'src/utils/CDate';
import { isValidEmail } from 'src/utils/email';
import { showErrorToast } from 'src/utils/errors';

import styles from './styles.module.scss';

export type AddEventModalAdditionalInfoType =
  | { onSave?: (data: EventContract) => void; onDelete?: (eventId: number) => void; eventId?: number }
  | undefined;

type FormValues = RecursiveNullable<{
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  projectId: number;
  guests: string[];
}>;

export const EventModal: FC = () => {
  const tHelper = useTHelper('modals.addEvent');

  const { closeModal, additionalInfo } = useModalsStore(
    (state) => ({
      closeModal: () => state.toggleModals('eventModal', false),
      additionalInfo: state.eventModal.additionalInfo as AddEventModalAdditionalInfoType,
    }),
    shallow,
  );

  const eventId = additionalInfo?.eventId;

  const isEventIdInner = eventId === undefined || (eventId || 0) > 0;

  const [loading, setLoading] = useState(false);

  const [ready, setReady] = useState(false);

  const { apiCaller } = useRequest();

  const formProps = useStateForm<FormValues>();

  useEffect(() => {
    if (eventId) {
      const event = useCalendarStore.getState().events.response?.find((event) => event.id === eventId);

      if (event) {
        formProps.reset({
          name: event.name,
          date: prepareSetDateToUiDatePicker(event.startDate),
          startTime: CDate.format(new Date(event.startDate), 'HH:mm:ss'),
          endTime: CDate.format(new Date(event.endDate), 'HH:mm:ss'),
          description: event.description,
          projectId: event.projectId,
          guests: event.guestEmails,
        });
      }
    }

    setReady(true);
  }, [eventId, apiCaller, formProps]);

  const onDelete = useCallback(
    (eventId: number) => () => {
      apiCaller(apiDeleteEvent, { id: eventId })
        .then(() => {
          if (isFunction(additionalInfo?.onDelete)) {
            additionalInfo?.onDelete(eventId);
          }

          closeModal();

          UiToast.commonSuccessDeleted();
        })
        .catch(showErrorToast);
    },
    [additionalInfo, apiCaller, closeModal],
  );

  const onSave = useCallback(
    (formData: FormValues) => {
      // setLoading(true);

      const date = new Date(formData.date as string);

      const getDateTime = (time: string) => {
        const [hours, minutes] = time.split(':');

        return CDate.setTime(date, {
          hours: +hours,
          minutes: +minutes,
        });
      };

      const apiFn = eventId ? apiUpdateEvent : apiCreateEvent;

      let startDate = getDateTime(formData.startTime as string);

      const endDate = getDateTime(formData.endTime as string);

      if (differenceInMinutes(startDate, endDate) === 0) {
        startDate = CDate.addTime(startDate, {
          minutes: -1,
        });
      }

      apiCaller(apiFn, {
        id: eventId || 0,
        name: formData.name as string,
        description: formData.description as string,
        startDate,
        endDate,
        projectId: formData.projectId as number,
        guestEmails: (formData.guests || []).filter((email) => isValidEmail(email as string)) as string[],
      })
        .then(({ data }) => {
          if (isFunction(additionalInfo?.onSave)) {
            additionalInfo?.onSave(data);
          }

          closeModal();

          UiToast.commonSuccessSaved();
        })
        .catch(showErrorToast)
        .finally(() => {
          setLoading(false);
        });
    },
    [additionalInfo, apiCaller, closeModal, eventId],
  );

  return (
    <UiModal onClose={closeModal} className={styles.modal} bodyClassName={styles.body}>
      <UiInput
        formProps={formProps}
        name="name"
        placeholder={tHelper('namePlaceholder')}
        className={styles.inputName}
        required
      />

      <div className={styles.three}>
        <UiDatePicker formProps={formProps} name="date" required />
        <UiTimePicker formProps={formProps} name="startTime" placeholder={tHelper('startTime')} required />
        <UiTimePicker formProps={formProps} name="endTime" placeholder={tHelper('endTime')} required />
      </div>

      <UiTaginput formProps={formProps} name="guests" label={tHelper('guestsLabel')} emailMode />

      <div className={styles.two}>
        <UiProjectsDropdown
          ownName="projectId"
          ownFormProps={formProps}
          required
          label={tHelper('projectLabel')}
          isClearable={false}
        />

        <UiInput
          formProps={formProps}
          name="description"
          label={tHelper('descriptionLabel')}
          type="textarea"
          className={styles.textarea}
        />
      </div>

      <div className={styles.submitWrapper}>
        {isEventIdInner ? (
          <UiDeleteConfirmPopover onConfirm={onDelete(eventId as number)} placement="right">
            <div className={styles.delete}>
              <IconDelete />
            </div>
          </UiDeleteConfirmPopover>
        ) : (
          <div />
        )}
        {isEventIdInner && (
          <UiButton
            type="submit"
            className={styles.submit}
            loading={loading || !ready}
            onClick={formProps.onSubmit(onSave)}
          >
            {tHelper(eventId === undefined ? 'create' : 'update')}
          </UiButton>
        )}
      </div>
    </UiModal>
  );
};
